import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { managementApi } from '../../../utils/agent';
import { Terms } from '../../pages/Terms';
import { WelcomeText } from '../../pages/WelcomeText';
import { Spinner } from '../Spinner';
import { PendingScreen } from './LoginFlow/PendingScreen';
import { RespondentForm } from './LoginFlow/RespondentForm';
import { RespondentNotFound } from './LoginFlow/RespondentNotFound';
import { UserRecognitionForm } from './LoginFlow/UserRecognitionForm';

// SCREENS
const WELCOME_TEXT = 'WELCOME_TEXT';
const TERMS = 'TERMS';
const RECOGNIZE_RESPONDENT = 'RECOGNIZE_RESPONDENT';
const RESPONDENT_NOT_FOUND = 'RESPONDENT_NOT_FOUND';
const RESPONDENT_FORM = 'RESPONDENT_FORM';
const PENDING_SCREEN = 'PENDING_SCREEN';

const ManagedGroupPreTestComponent = ({ welcomeText }) => {
  const [activeScreen, setActiveScreen] = useState(TERMS);
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  
  const DEFAULT_COMPLETE_BIO_TEMPLATE = 0;
  const [bioTemplatesData, setBioTemplatesData] = useState([]);
  const userId = useSelector((state) => state.questionnaireDetails.managedGroup.sessionData.userID);
  const inviteId = useSelector((state) => state.questionnaireDetails.managedGroup.sessionData.inviteId);
  const {
    managedGroup: { sessionData },
  } = useSelector((state) => state.questionnaireDetails);

  useEffect(() => {
    if (activeScreen === RECOGNIZE_RESPONDENT) {
      setEmail('');
    }
  }, [activeScreen]);
  
  let selectedBiodataTemplateId = useSelector((state) => state.questionnaireDetails.managedGroup.sessionData.biodataTemplateId) || 0;
  let selectedBiodataTemplate = bioTemplatesData.find((template) => template.id === selectedBiodataTemplateId);

  useEffect(() => {
    managementApi.fetchBiodataTemplates(userId)
      .then(({ data }) => {
        setBioTemplatesData(data)
      })
      .catch((e) => console.error(e));

    return () => { }
  }, [])

  if (bioTemplatesData.length === 0) {
    selectedBiodataTemplate = null;
    if (![0, -1].includes(selectedBiodataTemplateId)) {
      selectedBiodataTemplateId = DEFAULT_COMPLETE_BIO_TEMPLATE;
    }
  }

  return React.useMemo(() => {
    switch (activeScreen) {
      case WELCOME_TEXT:
        return (
          <WelcomeText
            text={welcomeText}
            onNext={() => {
              setActiveScreen('TERMS');
            }}
          />
        );
      case TERMS:
        return (
          <Terms
            onCancel={() => console.info('zzz cancel')}
            removeCloseButton
            onNext={() =>
              sessionData.skipAuthorization ? setActiveScreen(RESPONDENT_FORM) : setActiveScreen(RECOGNIZE_RESPONDENT)
            }
          />
        );
      case RECOGNIZE_RESPONDENT:
        return (
          <UserRecognitionForm
            goToRespondentForm={() => setActiveScreen(RESPONDENT_FORM)}
            goToPending={() => setActiveScreen(PENDING_SCREEN)}
            setEmail={setEmail}
            onError={() => setActiveScreen(RESPONDENT_NOT_FOUND)}
          />
        );
      case RESPONDENT_NOT_FOUND:
        return (
          <RespondentNotFound
            onBack={() => setActiveScreen(RECOGNIZE_RESPONDENT)}
            onNewRecord={() => setActiveScreen(RESPONDENT_FORM)}
            email={email}
          />
        );
      case RESPONDENT_FORM:
        return (
          <RespondentForm
            onSuccess={() => setActiveScreen(PENDING_SCREEN)}
            biodataTemplate={selectedBiodataTemplate}
            biodataTemplateId={selectedBiodataTemplateId}
            dispatch={dispatch}
          />
        );
      case PENDING_SCREEN:
        return <PendingScreen key={inviteId} />;
      default:
        return <Spinner />;
    }
  }, [activeScreen, welcomeText]);
};

export const ManagedGroupPreTest = withRouter(ManagedGroupPreTestComponent);
