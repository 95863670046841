import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Terms } from '../../pages/Terms';
import { Spinner } from '../Spinner';
import { RespondentForm } from '../ManagedGroup/LoginFlow/RespondentForm';
import { managementApi } from '../../../utils/agent';

// SCREENS
const TERMS = 'TERMS';
const RESPONDENT_FORM = 'RESPONDENT_FORM';

const OpenGroupPreTestComponent = ({ welcomeText }) => {
  const [activeScreen, setActiveScreen] = useState(TERMS);
  
  const DEFAULT_COMPLETE_BIO_TEMPLATE = 0;
  const [bioTemplatesData, setBioTemplatesData] = useState([]);
  const userId = useSelector((state) => state.questionnaireDetails.openGroup.userID);

  const {
    openGroup: { langID },
  } = useSelector((state) => state.questionnaireDetails);

  const onCreateRespondent = ({ accountID, inviteId, password }) => {
    const paddedAccountId = String(accountID).padStart(5, '0');
    const paddedInviteId = String(inviteId).padStart(7, '0');
    const link = `${window.location.origin}/login/${paddedAccountId}${paddedInviteId}/${password}/${langID}`;
    document.location.assign(link);
  };

  let selectedBiodataTemplateId = useSelector((state) => state.questionnaireDetails.openGroup.biodataTemplateId) || 0;
  let selectedBiodataTemplate = bioTemplatesData.find((template) => template.id === selectedBiodataTemplateId);

  useEffect(() => {
    managementApi.fetchBiodataTemplates(userId)
      .then(({ data }) => {
        setBioTemplatesData(data)
      })
      .catch((e) => console.error(e));

    return () => { }
  }, [])

  if (bioTemplatesData.length === 0) {
    selectedBiodataTemplate = null;
    if (![0, -1].includes(selectedBiodataTemplateId)) {
      selectedBiodataTemplateId = DEFAULT_COMPLETE_BIO_TEMPLATE;
    }
  }
  return React.useMemo(() => {
    switch (activeScreen) {
      case TERMS:
        return (
          <Terms
            onCancel={() => console.log('zzz cancel')}
            removeCloseButton
            onNext={() => setActiveScreen(RESPONDENT_FORM)}
          />
        );
      case RESPONDENT_FORM:
        return (
          <RespondentForm
            onSuccess={onCreateRespondent}
            isOpenGroup
            biodataTemplate={selectedBiodataTemplate}
            biodataTemplateId={selectedBiodataTemplateId}
          />
        );
      default:
        return <Spinner />;
    }
  }, [activeScreen, welcomeText]);
};

export const OpenGroupPreTest = withRouter(OpenGroupPreTestComponent);
